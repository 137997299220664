import { IColumn } from 'core/models/report-response';
import React from 'react';
import { Formatters } from './formatters';
import { AggExprTypes, ExpressionTypes } from './report-expressions';

export enum ReportTypeName {
  PinnedReport = 'Pinned Reports'
}

export enum ReportListSortType {
  ReportNameAZ = 'A-Z',
  ReportNameZA = 'Z-A'
}

export enum LSQLinkType {
  LSQ = 'LINKTOLSQ',
  Marvin = 'LINKTOMARVIN',
  ServiceCloud = 'LINKTOSERVICECLOUD',
  SelfOrExternal = 'LINKTOSELF',
}

export enum DisplayType {
  Dashboard = 'Dashboard',
  Report = 'Report'
}

export enum ReportIcon {
  Phone = 'Phone',
  Bulb = 'Bulb',
  Star = 'Star',
  Sheet = 'Sheet',
  Person = 'Person',
  Revenue = 'Revenue',
  Custom = 'Custom',
  MyDashboard = 'MyDashboard'
}

export enum ReportActionTypes {
  Pin = 'Pin',
  Unpin = 'Unpin',
  CopyEmbedURL = 'Copy Embed URL',
  EditTags = 'Edit Tags'
}

export enum ToolbarMenuItemTypes {
  Filters = 'cmn.filters',
  Setting = 'cmn.data',
  ZeroValues = 'cmn.zeroValues',
  Charts = 'cmn.visualise',
  PinnedReports = 'cmn.pinnedReports'
}

export enum ToolbarDisplayMenuItems {
  Filters = 'cmn.filters',
  Setting = 'cmn.data',
  ZeroValues = 'cmn.zeroValues',
  Charts = 'cmn.visualise',
  PinnedReports = 'cmn.pinnedReports'
}

export enum ReportDimensionsSettingsTypes {
  AvailableFields = 'AdditionalFields',
  RowGrouping = 'RowGrouping',
  ColumnGrouping = 'ColumnGrouping',
  Measures = 'Measures',
}

export enum ReportDimensionsSettingsTitles {
  AvailableFields = 'Additional Fields',
  RowGrouping = 'Row Grouping',
  ColumnGrouping = 'Column Grouping',
  AvailableFilters = 'Add Filters',
  DrilldownColumn = 'Drilldown Column',
  AddValues = 'Add Values',
  Measures = 'Values',
}

export enum SideTrayType {
  Filters = 'Filters',
  RowGroup = 'RowGroup',
  ColGroup = 'ColGroup',
  Values = 'Values',
  Drilldown = 'Drilldown',
  SwitchFilters = 'SwitchFilters',
  SwitchFields = 'SwitchFields',
  DataSourceEntity = 'DataSourceEntity',
  DataSourceField = 'DataSourceField',
  None = 'None',
}

export enum ReportDimensionsSettingsDescs {
  RowGrouping = 'Change the way the rows are grouped. Use Drag and Drop to change the order.',
  ColumnGrouping = 'Change the way the columns are grouped. Use Drag and Drop to change the order.',
  DrilldownColumn = 'Change the way the drilldown columns are grouped. Use Drag and Drop to change the order.',
  AddValues = 'Change the way the values are arranged. Use Drag and Drop to change the order.',
}

export const ReportDimensionsZeroValuesTitle = 'Show records with no values';
export const ReportDimensionsZeroValuesDesc = 'View rows with zero values in report. This may impact report load time.';

export enum ColumnActionTypes {
  Drilldown = 'DRILLDOWN',
  AutoDrillDown = 'AUTODRILLDOWN',
  SetFilter = 'SET_FILTER'
}

export enum ColumnActionStyles {
  LinkPrimary = 'link-primary',
}

export enum DrilldownChangesTypes {
  FilterOnly = 'Filter_Only',
}

export enum DrilldownChangeTypes {
  Filter = 'Filter',
}

export enum ColumnDataTypes {
  Default = '',
  String = 'String',
  Int = 'Int',
  Int64 = 'Int64',
  Decimal = 'Decimal',
  Time = 'Time',
  DateTime = 'DateTime',
  Boolean = 'Boolean',
  StringArray = 'StringArray',
  Int64Array = 'Int64Array',
}

export enum ColumnRenderTypes {
  DefaultRenderType = 'Default',
  Phone = 'Phone',
  Email = 'Email',
  URL = 'URL',
  MultiSelectRender = 'MultiSelect',
  NoneRenderType = 'None',
  TextBox = 'Textbox',
  TextArea = 'TextArea',
  Notes = 'Notes',
  Counter = 'Counter',
  Currency = 'Currency',
  IPRender = 'IP',
  SelectRender = 'Select',
  Calender = 'Calender',
  DateRender = 'Date',
  Datetime = 'Datetime',
  RadioButtons = 'RadioButtons',
  GroupButtons = 'GroupButtons',
  ComboBox = 'ComoBox',
  Checkbox = 'Checkbox',
  DropdownWithCheckBox = 'DropdownWithCheckBox',
  DropdownWithOthers = 'DropdownWithOthers',
  TimeRender = 'Time',
  SearchableDropDown = 'SearchableDropDown',
  Compound = 'Compound',
  LsqDropdown = 'Dropdown'
}
export const ColumnDataTypesList = {
  Default: 'Default',
  String: 'String',
  Int: 'Int',
  Int64: 'Int64',
  Decimal: 'Decimal',
  Time: 'Time',
  DateTime: 'DateTime',
  Boolean: 'Boolean',
  StringArray: 'StringArray',
  Int64Array: 'Int64Array',
};
export const NumericColumnDataTypesList = [ColumnDataTypesList.Int, ColumnDataTypesList.Int64, ColumnDataTypesList.Int64Array, ColumnDataTypesList.Decimal];
export const DateColumnDataTypesList = [ColumnDataTypesList.DateTime, ColumnDataTypesList.Time];

export enum OperatorTypes {
  BETWEEN = 'BETWEEN',
  IN = 'IN',
  EQ = 'EQ'
}

export enum DimensionStatus {
  NotApplied = 0,
  Applied,
  Mandatory,
}

export enum ZeroAnchorStatus {
  Disabled = 0,
  Enabled,
  Applied,
}

export enum ColumnModes {
  Hidden = 0,
  Visible,
  Sortable,
  NotApplied,
}

export enum DimensionItemWarning {
  OnRemove = 'Unselecting this dimension will also disable the associated filter.',
  MinGrouping = 'Minimum {{count}} dimension has to be remain selected',
  MaxGrouping = 'Maximum Limit of {{count}} selected dimension is reached',
}

export enum ZeroValuesHelpText {
  Disabled = 'Zero Values against this dimension is not allowed.'
}

export enum MinRowGrouping {
  Table = 1,
  ColumnChart = 1,
  LineChart = 1,
  AreaChart = 1,
  BarChart = 1,
  summary = 1,
  multiline = 1,
  NoData = 1,
  PieChart = 1,
  DonutChart = 1
}

export enum MaxRowGrouping {
  Table = 3,
  ColumnChart = 3,
  LineChart = 3,
  AreaChart = 3,
  BarChart = 3,
  summary = 2,
  multiline = 2,
  NoData = 2,
  PieChart = 3,
  DonutChart = 3
}
export enum MinColumnGrouping {
  Table = 1,
  ColumnChart = 1,
  LineChart = 1,
  AreaChart = 1,
  BarChart = 1,
  summary = 1,
  multiline = 1,
  NoData = 1,
  PieChart = 1,
  DonutChart = 1
}

export enum MaxColumnGrouping {
  Table = 1,
  ColumnChart = 1,
  LineChart = 1,
  AreaChart = 1,
  BarChart = 1,
  summary = 1,
  multiline = 1,
  NoData = 1,
  PieChart = 1,
  DonutChart = 1
}

export enum DimensionMode {
  RowGroup = 0, // default
  ColumnGroup = 1,
  Both = 2,
}

export enum Aggregation {
  Sum = 'SUM',
}

export const DimensionIdentifier = 'DIMENSIONIDENTIFIER=';

export const DimensionNoValue = '[No Value]';

export const BinningZeroValue = '0';

export const PivotColGrpHeaderSeparator = '<SIERA_INTERNAL_REPLACE>';

export const EncryptedText = 'xxxxx';

export const TotalSummaryText = 'Summary';

export const SieraAutoDrilldownLink = 'siera-autodrilldown-link';

export enum FieldEntitiesType {
  Lead = 'Lead',
  Activity = 'Activity',
  Opportunity = 'Opportunity',
  Task = 'Task',
  User = 'User',
  Custom = 'Custom',
  Ticket = 'Ticket',
  TicketActivity = 'TicketActivity',
  Accounts = 'Accounts',
  AccountsActivity = 'AccountsActivity',
  WebContent = 'WebContent',
  LeadSystemAudit='LeadSystemAudit'
}

export enum ProductLinesTypes {
    SalesCloud = 'Sales',
    Converse = 'Converse',
    LandingPagePro = 'Landing Page Pro',
    ServiceCloud = 'Service',
}

export const PreDefinedFieldEntityType = 'Pre-defined';

export const DateTimeBinning = [
  {
    label: 'None',
    value: 'NoneExpr',
    example: '',
    abbreviation: '',
  },
  {
    label: 'Hour of the Day',
    value: 'ToHourExpr',
    example: 'Ex: 00:00, 01:00....23:00',
    abbreviation: 'HOD',
  },
  {
    label: 'Date',
    value: 'ToDateOfYearExpr',
    example: 'Ex: Jan 01, 2022',
    abbreviation: '',
  },
  {
    label: 'Month of the Year',
    value: 'ToMonthOfYearExpr',
    example: 'Ex: Jan, Feb',
    abbreviation: 'MOY',
  },
  {
    label: 'Month',
    value: 'ToMonthExpr',
    example: 'Ex: Jan 2022, Feb 2022',
    abbreviation: '',
  },
  {
    label: 'Year',
    value: 'ToYearExpr',
    example: 'Ex: 2021, 2022',
    abbreviation: '',
  },
  {
    label: 'Quarter',
    value: 'ToQuarterExpr',
    example: 'Ex: 2021 Q1, 2022 Q2',
    abbreviation: '',
  },
  {
    label: 'Quarter of the year',
    value: 'ToQuarterOfYearExpr',
    example: 'Ex: Q1, Q2',
    abbreviation: 'QOY',
  },
  {
    label: 'Week of the year',
    value: 'ToYearWeekExpr',
    example: 'Ex: Week1, Week2',
    abbreviation: 'WOY',
  },
  {
    label: 'Week',
    value: 'ToWeekExpr',
    example: 'Ex: 2021 W1, 2021 W2',
    abbreviation: '',
  },
  {
    label: 'Day',
    value: 'ToDayOfWeekExpr',
    example: 'Ex: Sunday, Monday, Tuesday',
    abbreviation: '',
  },
];

export enum MaskedMessage {
  AvailableFilter = 'Filter cannot be added due to Permission Template',
  AppliedFilter = 'Filter disabled due to Permission Templates',
  AvailableDimension = 'Field cannot be added due to Permission Template',
  Header = 'Permission Template Applied',
  HeaderTooltip = 'Some data has been restricted due to Permission Templates',
}

export const MaxDrilldownGrouping = 10;

export enum ExportStatus {
  InProgress = 'IN-PROGRESS',
  InQueue = 'IN-QUEUE',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum formatType {
  CSV = 'CSV',
  PDF = 'PDF',
  Excel = 'Excel',
  PNG = 'PNG',
  JPG = 'JPG'
}

export const ExportNote = 'Note : Exporting will take some time. Once the file is ready, it will be available in this section.';

export enum dropdownExportButtonNames {
  CSV = 'Quick CSV Export',
  Excel = 'Quick XLSX Export',
  Export = 'Export...',
  PNG = 'Export Chart as PNG',
  JPG = 'Export Chart as JPG',
  PDF = 'Export Chart as PDF'
}

export const dailyQuotaApiResponse = 'Your daily quota for exported reports has exceeded';

export const dailyQuotaWarning = 'Export limit reached. Try again after {{timeLeft}}';

export const dailyQuotaTooltip = 'Export in progress...';

export enum headerOptions {
  AppliedFilters = 'Applied Filters'
}

export enum TooltipPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  RightTop = 'rightTop',
  RightBottom = 'rightBottom',
  LeftTop = 'leftTop',
  LeftBottom = 'leftBottom',
}

export const TooltipDelayTime = 1.5;

export const DefaultTimezone = 'US/Alaska';

export const InternationalDateLineWest = 'International Date Line West';

export enum JobTypes {
  Merge = 'Merge',
  Load = 'Load'
}

export const dataUpdate = 'The data has been updated';

export const loadNewData = 'Click on the refresh icon to load the new data.';

export const updateNewData = 'Click on the refresh icon to load the modified data.';

export const jobInfoTextLoad = 'jic.jobInfoTextLoad';
export const jobInfoTextMerge = 'jic.jobInfoTextMerge';

export enum Timers {
  Minute = 60000,
  OneSecond = 1000,
  ThreeSeconds = 3000,
  FiveSecondsInMs = 5000,
  FiveSeconds = 5,
  EightSecond = 8000,
  FiftenHundredMilliSeconds = 1500,
  HalfSeconds = 500,
  HundredMilliSeconds = 100,
  ZeroSeconds = 0,
  SixHundredMilliSeconds = 600,
  EightHundredMilliSeconds = 800
}

export enum ReportViewTypes {
  Default = 'Default',
  Custom = 'Custom',
  Shared = 'Shared',
}

export enum GetUserLoaderIcon {
  InitialLoad = '',
  Spinner = 'spinner',
  Arrow = 'arrow',
  Tick = 'tick'
}

export enum QueryParamNames {
  SourceId = 'sId',
  DrilldownStateID = 'ddSId',
  LsqMarvinToken = 'lsqMarvinToken',
  IsMarvin = 'isMarvin',
  ShowToast = 'st',
  Referral = 'referral',
  SelectedTab= 'selectedTab'
}

export enum ShowToastTypes {
  SAVEAS = '1'
}

export enum FilterButtonTypes {
  reset = 'reset',
  apply = 'apply',
}

export enum ExpandListButtonContext {
  Filters = 0,
  RowGroup,
  ColGroup,
  Drilldown,
  Values,
  IndividualFilters,
  DrilldownConfig,
  DrilldownConfigFields,
  SwitchUserFields,
  EditDataSource,
  AddJoinBlock
}

export enum MaxLimitReached {
  ColGroup = 1,
  RowGroup = 3,
  Measures = 25,
  Filters = 15,
  Tags = 15
}

export enum AdditionalFieldTitle {
  RowGroup = 'Add Row Grouping',
  ColGroup = 'Add Column Grouping',
  Drilldown = 'Add Columns',
}

export const expandFiltersButtonLabel = 'Filters';
export const expandFieldsButtonLabel = 'Fields';
export const expandValuesButtonLabel = 'Values';

export const AppliedFilter = 'Applied Filters: ';
export const MinMeasuresWarning = 'Minimum 1 value has to remain selected.';

export enum SettingsPanelTypes {
  RowGrouping,
  ColumnGrouping,
  Values,
  None,
}

export const dynamicMeasureDefaultConfig: IColumn = {
  Name: '',
  Id: '',
  BuilderConfig: {
    Alias: '',
    SchemaName: '',
    DataType: ColumnDataTypes.Default,
    DisplayName: '',
    IsDynamicField: false,
    Entity: '',
    RenderType: ColumnRenderTypes.NoneRenderType,
  },
  Props: {
    AllowDrillDown: true,
    IsMasked: false,
    Formatter: {
      FormatterMeta: {
        Decimal: 0,
        Seperator: 1000,
      },
      FormatterType: Formatters.NUMBER,
    },
    Mode: ColumnModes.NotApplied,
    Measure: {
      IsMeasure: true,
      Name: '',
      Expression: {
        Type: ExpressionTypes.Aggregation,
        SubType: AggExprTypes.Sum,
        Part: {
          ExpressionToSum: {
            SubType: AggExprTypes.Sum,
            Part: {
              Column: {
                SchemaName: '',
                DataType: ColumnDataTypes.Int,
                DisplayName: '',
              },
            },
            Type: ExpressionTypes.Column,
          },
        },
      },
    },
  },
};

export enum FormButtonTypes {
  cancel = 'cancel',
  apply = 'apply',
}

export class AutomationClassNames {
  static RowGroupedFields = 'se-automation-row-grouping-fields';

  static ColGroupedFields = 'se-automation-col-grouping-fields';

  static AppliedValues = 'se-automation-applied-values';

  static AppliedFilters = 'se-automation-applied-filters';

  static AppliedDrilldownFields = 'se-automation-applied-drilldown-fields';

  static Lead = 'se-automation-lead-entity';

  static Opportunity = 'se-automation-opportunity-entity-dropdown';

  static Activity = 'se-automation-activity-entity-dropdown';

  static Task = 'se-automation-task-entity-dropdown';

  static User = 'se-automation-user-entity-dropdown';

  static 'Pre-defined' = 'se-automation-pre-defined-entity-dropdown';

  static PinnedColums = 'se-automation-pinned-Columns';
}
export enum FilterCategories {
  DefaultFilters = 0,
  UserFilters,
  AdditionalFilters,
}

export class FilterConstant {
  static NoFilter = 'No filters applied in this section';

  static DefaultFilterSectionLabel = 'Default Filters';

  static UserFilterSectionLabel = 'User Filters';

  static AdditionalFilterSectionLabel = 'Additional Filters';

  static DisabledFilters = 'Disabled Filters';

  static DisabledFFiltersDesc = 'These filters will be active when associated fields in grouping are checked.';

  static SingleFilterHeight = 41;
}

export enum statusCodes {
  Success = 200,
  NoContent = 204,
  Unauthorized = 401,
  Forbidden = 403,
  Unprocessable = 422,
}
export enum RowGroupingLevel {
  One = 1,
  Two = 2,
  Three = 3,
}

export enum RotationValues {
  horizontal = 0,
  // We are using undefined to set the highchart autorotation property
  autoRotate = undefined,
  vertical = 270,
}

export enum ChartExportFormatTypes {
  PNG = 'image/png',
  JPG = 'image/jpeg',
}

export const UserSalesGroupFieldAlias = 'GroupId';
export enum draggableItemType {
  RowDim,
  ColumnDim,
  Measure,
  DrilldownDim
}

export const Others = '{Others}';
export const Summary = '{Summary}';
export const GridRootNodeId = 'ROOT_NODE_ID';
export const SubTotalsSeperator = '{SIERA_SubTotal}';
export const GroupedSubTotalsSeperator = '{SIERA_Grouped_SubTotal}';

export enum AccessType {
  report = 'Reports',
  dashboard = 'Dashboards',
}

export const MIN_CHARACTER_LIMIT = 3;
export const MAX_NAME_CHARACTER_LIMIT = 60;
export const MAX_DESC_CHARACTER_LIMIT = 120;
export const MAX_EXP_NAME_CHARACTER_LIMIT = 40;
export const MAX_FILTER_NAME_CHARACTER_LIMIT = 20;
export const MIN_SYMBOL_CHARACTER_LIMIT = 1;
export const MAX_SYMBOL_CHARACTER_LIMIT = 3;
export const MAX_MEASURE_NAME_CHARACTER_LIMIT = 40;

export enum FormInputTypes {
  name = 'reportName',
  desc= 'reportDesc',
  expName='customExpName',
  dashboardName= 'dashboardName',
  dashboardDesc= 'dashboardDesc',
  default = 'default',
  widget = 'widgetName',
  dashboardFilterName ='dashboardFilterName'
}

export const invalidExcelStringValues = {
  plus: '+',
  equals: '=',
  hypen: '-',
  at: '@',
};

export const alignment = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  center: 'center',
  right: 'right',
  bottom: 'bottom',
  middle: 'middle',
};

export interface TooltipProps {
  overlay: React.ReactNode;
  children: React.ReactNode;
}

export const DrillDownColumnLimit = 10;

export const maxConfigurationLimit = 25;

export const FlatJoinsLimit = 3;
export enum aggregators {
  Sum = 'Sum',
}
export enum DataSourceSelectionType {
  lhsTable = 0,
  rhsTable = 1,
  lhsField = 2,
  rhsField = 3
}
